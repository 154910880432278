import { BPConfig } from "../types";
import { ServiceBase } from "./ServiceBase";

export class ConfigService extends ServiceBase {
  static instance: ConfigService;

  constructor(url?: string) {
    super(url);
  }

  static getInstance() {
    if (!ConfigService.instance) {
      ConfigService.instance = new ConfigService(
        process.env.REACT_APP_BP_MEDIA_HOST
      );
    }

    return ConfigService.instance;
  }

  async getConfig(): Promise<BPConfig | undefined> {
    return this.genericRequest<BPConfig | undefined>({
      url: "/configv3.json",
      method: "GET",
    });
  }
}
