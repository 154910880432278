import { Box, useMediaQuery, useTheme } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import SearchBar from "./SearchBar";
import { BannerImage } from "./components/BannerImage/BannerImage";
import Instructive from "./components/Instructive/Instructive";
import MedicalAreas from "./components/MedicAreas/MedicalAreas";
import WhatsAppBanner from "./components/WhatsAppBanner/WhatsAppBanner";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { ConfigService } from "../../services/ConfigService";
import { IBannerConfig, BannerSet } from "../../types";
import { useSelector } from "react-redux";
import { subsidiarySelector } from "../../store/selectors";
import latinize from "latinize";

const LandingPage: FC<{}> = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const subsidiary = useSelector(subsidiarySelector);
  const [_, setConfig] = useState<IBannerConfig | undefined>(undefined);
  const [bannerImgs, setBannerImgs] = useState<string[]>([]);

  const getBannersForViewport = React.useCallback(
    (sets: BannerSet[], subsidiary: string, viewport: string) =>
      sets.map(
        (bannerSet) =>
          `${process.env.REACT_APP_BP_MEDIA_HOST}/banners-${subsidiary}/${bannerSet[viewport]}`
      ),
    [subsidiary]
  );

  useEffect(() => {
    if (subsidiary && !bannerImgs.length) {
      ConfigService.getInstance()
        .getConfig()
        .then((response) => {
          if (!response) return;

          const { images } = response;
          setConfig(images);

          const subsidiaryName = latinize(subsidiary?.name || "").toLowerCase();
          const viewport = isMobile ? "mobile" : "web";
          const imageUrls = images[subsidiaryName].map(
            (banner) =>
              `${process.env.REACT_APP_BP_MEDIA_HOST}/banners-${subsidiaryName}/${banner[viewport]}`
          );

          setBannerImgs(imageUrls);
        });
    }
  }, [subsidiary, isMobile, bannerImgs]);

  const shouldShowBanner = !!bannerImgs.length;

  return (
    <main>
      <Box
        sx={{
          display: { lg: "block", md: "block", sm: "block", xs: "block" },
          background: "white",
          height: { lg: 400, md: 310, sm: 230, xs: 180 },
          margin: "0 auto",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {shouldShowBanner && (
          <Swiper
            navigation
            pagination
            rewind
            modules={[Navigation, Pagination]}
            className="bp-carousel"
          >
            {bannerImgs.map((img) => (
              <SwiperSlide>
                <BannerImage src={img} />
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </Box>
      <SearchBar />
      <div className="App">
        <Instructive />
        <br />
        <MedicalAreas />
        <WhatsAppBanner
          number="+525633575330"
          message="Hola. Quisiera recibir información"
        />
      </div>
    </main>
  );
};

export default LandingPage;
